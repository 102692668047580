import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Wrapper from "../components/wrapper"
import Breadcrumb from "../components/breadcrumb"

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) => {
  const posts = data.allWpPost.nodes

  if (!posts.length) {
    return (
      <Layout isHomePage>
        <p>
          No contents found. Add posts to your site and they'll appear here!
        </p>
      </Layout>
    )
  }

  return (
    <Layout isHomePage>
      <Breadcrumb tax="" term="最新" />
      <ol style={{ listStyle: `none` }}>
        {React.Children.toArray(
          posts.map(post => {
            return (
              <li>
                <Wrapper post={post} />
              </li>
            )
          })
        )}
      </ol>

      {previousPagePath && (
        <>
          <Link to={previousPagePath}>Previous page</Link>
          <br />
        </>
      )}
      {nextPagePath && <Link to={nextPagePath}>Next page</Link>}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        id
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`
